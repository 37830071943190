@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */
@import "glightbox.css";

h1,
h2,
h3,
h4,
h5 {
  @apply font-bold;
}

body {
  @apply leading-relaxed;
  font-size: 16px;

  @media (min-width: 640px) {
    font-size: 16px;
  }
}

.max-h-64 {
  max-height: 16rem;
}

.rich-text {
  @apply leading-normal;
  & > * + *,
  & li > p + p {
    @apply mt-6;
  }
  & strong {
    @apply text-black font-bold;
  }
  & a {
    @apply text-black font-semibold;
  }
  & strong a {
    @apply font-bold;
  }
  & h2 {
    @apply leading-tight text-4xl;
  }
  & h3 {
    @apply leading-tight text-2xl;
  }
  & h4 {
    @apply leading-tight text-xl;
  }
  & ul,
  & ol {
    @apply pl-5;
    @screen sm {
      @apply pl-10;
    }
  }

  & ul {
    @apply list-disc;
  }
  & ol {
    @apply list-decimal;
  }

  img.full-width {
    margin: 0 auto;
  }
}

.responsive-object {
  position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed,
.videos iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videos iframe {
  margin: 0 auto;
}

.videos > div {
  position: relative;
  padding-bottom: 56.25%;
}

.podcast {
  position: relative;
  overflow: hidden;
  padding-bottom: 60%;
}

.podcast iframe {
  width: 100% !important;
  height: 100% !important;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.form label {
  @apply block mt-3 mb-1 text-left;
}

.form input,
.form textarea {
  @apply text-white text-lg bg-red-700 rounded w-full p-2;
}

.loader {
  display: none;
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(
    left,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(
    left,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: linear-gradient(
    to right,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader.show {
  display: block;
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: black;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* purgecss end ignore */
